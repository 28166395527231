import React, { useEffect, useState } from "react";
import Image from "next/image";
import { IconWithLabelProps } from "./types";
import classes from "./IconWithLabel.module.scss";
import classNames from "classnames";
const DEFAULT_FOLDER = "logos";
export const GENERATION_FOLDER = "generations";
const DEFAULT_PLACEHOLDER = "/img/placeholder-brand.png";

export const IconWithLabel = ({
    option,
    folder = DEFAULT_FOLDER,
    placeholderUri = DEFAULT_PLACEHOLDER
}: IconWithLabelProps) => {
    const [size, setSize] = React.useState(28);
    const [placeholder, setPlaceholder] = React.useState(placeholderUri);
    useEffect(() => {
        if (folder === GENERATION_FOLDER) {
            setPlaceholder("/img/generation-placeholder.svg");
        }
    }, [folder]);

    return (
        <div
            className={classNames(classes.IconLabel, {
                [classes.IconLabel__generation]: folder === GENERATION_FOLDER
            })}
        >
            <Image
                src={`${process.env.NEXT_PUBLIC_YANDEX_STORAGE_URL}/${folder}/${option.alias || option.value}.webp`}
                alt={option.label}
                unoptimized={true}
                width={size}
                height={size}
                onError={(e) => {
                    e.currentTarget.src = placeholder;
                    e.currentTarget.srcset = placeholder;
                }}
            />
            <span className={classes.IconLabel__name}>{option.label}</span>
        </div>
    );
};